model-viewer::part(ar-button) {
  z-index: 9999 !important;
  position: relative !important;
}

/* Assurez-vous que le footer a un z-index inférieur */
.PlayerFooter-verticalPlayerFooter {
  z-index: 1200;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
